.password-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 150px; // Set a specific width
  //height: 40px; // Set a specific height

  // Style the icons within the button
  svg {
    width: 20px; // Adjust the icon width
    //height: auto; // Maintain aspect ratio
    //margin-right: 5px; // Adjust spacing between icon and text
  }
}

/* Ensures the toggle button maintains rounded corners */
.input-group .password-toggle-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* When the adjacent input is invalid */
.input-group.is-invalid .password-toggle-btn {
  border-top-right-radius: 0.25rem; /* Adjust to match Bootstrap's default border-radius */
  border-bottom-right-radius: 0.25rem; /* Adjust to match Bootstrap's default border-radius */
}
