// dot of cart
.dot {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -3px;
    right: -3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $primary;
  }
}
