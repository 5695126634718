/* SCSS for the circle badge */
.circle-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px; // Size of the circle
  height: 30px; // Size of the circle
  background-color: rgba(0, 0, 0, 0.3); // Adjusted for 30% opacity
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em; // Adjust based on your preference
  font-weight: bold; // Make the font bold
}
