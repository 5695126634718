/* ProductCardZoom.css */

/* Flex property to make all cards the same height */
/* Flex property to make all cards the same height */
.productCardZoom {
  flex: 1 1 auto; /* Grow and shrink as needed, with an initial size of auto */
  margin: 16px 0; /* Add margin if needed */
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  border: none !important;
  display: flex;
  flex-direction: column; /* Ensure content inside card is stacked vertically */
  background: #fff; /* Optional: add a background color */
  padding: 16px; /* Optional: add padding inside the cards */
}

/* Ensure child elements grow to fill available space */
.productCardZoom > .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.productImageContainer {
  overflow: hidden;
}

.productCardZoom:hover .productImage {
  transform: scale(1.2); /* Zoom effect on hover */
}

.productImage {
  width: 100%; /* Fill up the available width */
  object-fit: cover; /* Maintain aspect ratio and cover entire container */
  margin-bottom: 10px;
  transition: transform 0.3s ease; /* Add transition for smooth zoom effect */
}

.productInfo h5 {
  font-size: 1.2em;
  font-weight: bold; /* Make the title bold */
  margin: 10px 0;
}

.productInfo p {
  font-size: 1.1em;
  color: #000000;
  margin-bottom: 0;
}

.custom-quantity-input {
  width: 120px !important; /* Adjust width as needed */
}
