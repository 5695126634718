.hero-carousel {
  .carousel-item {
    padding: 0; // Remove padding from carousel items
    height: 600px; // Set a fixed height for the carousel items
    @media (max-width: 768px) {
      height: 350px; // Adjust height for smaller screens if needed
    }

    img {
      margin: 0; // Remove margin from images
      width: 100%; // Ensure images cover the full width
      height: 100%; // Ensure images cover the full height
      object-fit: cover; // Ensure images cover the container without distortion
    }
  }

  .carousel-inner {
    padding: 0; // Remove padding from the carousel inner container
  }

  .carousel-caption {
    color: rgb(0, 0, 0); // Set the text color to black
  }

  .carousel-caption-background {
    background-color: rgba(
      255,
      255,
      255,
      0.7
    ); // Semi-transparent white background
    //padding: 10px; // Add padding for better readability
    border-radius: 5px; // Optional: Add rounded corners
  }
}
