.product-details-list {
  margin-bottom: 0;
  padding-left: 20px; /* To show bullets */
  list-style-type: disc; /* To show disc bullets */
}

.product-details-list li {
  position: relative;
  color: black; /* Ensures text color is black */
}

.product-details-list li::marker {
  color: lightgray; /* Styles the bullet points in gray */
}
